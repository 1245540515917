import React, { useContext } from "react"
import HorseButton from "../../../components/horse-button"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import radHorse from "./struggle.jpg"
import * as style from "./index.module.scss"

function StruggleHorse() {
  return (
    <Layout bodyClass={style.struggleHorseBody}>
      <Seo title="Horse or no Horse" />
      <div className={style.struggleHorseWrapper}>
        <img
          src={radHorse}
          className={style.struggleHorse}
          alt="A man you realize is supposed to represent you is plagued with the indecision between two choices. Your choices are:"
        />
        <div className={style.struggleButtonOne}>
          <p className={style.textHorses}>More horses</p>
          <HorseButton horseText="noHorseText" />
        </div>
        <div className={style.struggleButtonTwo}>
          <p className={style.textNoHorses}>No more horses</p>
          <button name="No more horses"></button>
        </div>
      </div>
    </Layout>
  )
}

export default StruggleHorse
